
import Header from "./Header";
import css from "./Layout.module.css";

const Layout = (props) => {
  return (
    <div className={css.mainContainer}>
      <Header />
      {props.children}
    
    </div>
  );
};

export default Layout;
