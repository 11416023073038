import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Assets/Images/Logo/Logo White.png";
import Cross from "../../Assets/Icons/Cross.svg";
/* import Burger from "./Burger"; */
import css from "./Header.module.css";

const Header = (props) => {
  const [scrollHeader, setScrollHeader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState();

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1250) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  const scrollHandler = () => {
    if (window.scrollY >= 300) {
      setScrollHeader(true);
    } else {
      setScrollHeader(false);
    }
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <header
      className={
        scrollHeader
          ? isMenuOpen
            ? css.burgerContainerScrolled
            : css.mainContainerScrolled
          : isMenuOpen
          ? css.burgerContainer
          : css.mainContainer
      }
    >
      {!isMenuOpen && (
        <Link to="/home" className={css.LogoLink}>
          <img
            src={logo}
            alt="CSS"
            className={scrollHeader ? css.logoScrolled : css.logo}
          />
        </Link>
      )}
      {isMobile ? (
        isMenuOpen ? (
          <div
            className={css.burgerOpenIcon}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className={css.burgerCrossContainer}>
            <img src={Cross} alt="Fermer le menu" className={css.burgerCross}/>
            </div>
          </div>
        ) : (
          <div
            className={css.burgerIcon}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        )
      ) : !isMenuOpen ? (
        <nav className={css.navContainer}>
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive ? `${css.navLinkActive}` : `${css.navLink}`
            }
          >
            Accueil
          </NavLink>
          <NavLink
            to="/solutions"
            className={({ isActive }) =>
              isActive ? `${css.navLinkActive}` : `${css.navLink}`
            }
          >
            Nos Solutions
          </NavLink>
          <NavLink
            to="/process"
            className={({ isActive }) =>
              isActive ? `${css.navLinkActive}` : `${css.navLink}`
            }
          >
            Comment ça marche
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? `${css.navLinkActive}` : `${css.navLink}`
            }
          >
            Qui sommes nous
          </NavLink>
          <NavLink
            to="/career"
            className={({ isActive }) =>
              isActive ? `${css.navLinkActive}` : `${css.navLink}`
            }
          >
            Nous rejoindre
          </NavLink>
          <NavLink
           to="/contact"
            className={({ isActive }) =>
              isActive ? `${css.navLinkActive}` : `${css.navLink}`
            }
          >
            Nous contacter
          </NavLink>
        </nav>
      ) : (
        <div
          className={css.burgerIcon}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
            <div className={css.burgerCrossContainer}>
            <img src={Cross} alt="Fermer le menu" className={css.burgerCross}/>
            </div>
        </div>
      )}
      {isMenuOpen && (
        <nav className={css.burgerNavContainer}>
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive ? `${css.burgerLinkActive}` : `${css.burgerLink}`
            }
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Accueil
          </NavLink>
          <NavLink
            to="/solutions"
            className={({ isActive }) =>
              isActive ? `${css.burgerLinkActive}` : `${css.burgerLink}`
            }
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Nos Solutions
          </NavLink>
          <NavLink
            to="/process"
            className={({ isActive }) =>
              isActive ? `${css.burgerLinkActive}` : `${css.burgerLink}`
            }
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Comment ça marche
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? `${css.burgerLinkActive}` : `${css.burgerLink}`
            }
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Qui sommes nous
          </NavLink>
          <NavLink
            to="/career"
            className={({ isActive }) =>
              isActive ? `${css.burgerLinkActive}` : `${css.burgerLink}`
            }
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Nous rejoindre
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
            isActive ? `${css.burgerLinkActive}` : `${css.burgerLink}`
          }
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
            Nous contacter
          </NavLink>
        </nav>
      )}
    </header>
  );
};

export default Header;
