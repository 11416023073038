import css from "./3-Process.module.css";

import { Fade } from "react-reveal";

import Footer from "../Components/General/Footer";
import { Helmet } from "react-helmet";

const Process = (props) => {
  return (
    <div className={css.mainSection}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSS - Comment ça marche</title>
        <link rel="canonical" href="http://bpo-css.com/process" />
        <meta
          name="description"
          content="Une procedure en 5 étapes de prise de contact à la mise en production de notre solution."
        />
      </Helmet>
      <section className={css.HowToSection}>
        <div className={css.HowToContainer}>
          <div className={css.LineContainer}>
            <div className={css.Line} />
            <div className={css.Number}>1</div>
          </div>
          <Fade clear>
            <div className={css.TextContainer}>
              <h2 className={css.Title}>Prise de contact</h2>
              <p className={css.Body}>
                Présentation de nos solutions et vérification de vos besoins.
              </p>
            </div>
          </Fade>
        </div>
        <div className={css.HowToContainer}>
          <div className={css.LineContainer}>
            <div className={css.Line} />
            <div className={css.Number}>2</div>
          </div>
          <Fade clear>
            <div className={css.TextContainer}>
              <h2 className={css.Title}>Cahier des charges</h2>
              <p className={css.Body}>
                Définition du cahier des charges avec mise en place des
                indicateurs de performance (KPI).
              </p>
            </div>
          </Fade>
        </div>
        <div className={css.HowToContainer}>
          <div className={css.LineContainer}>
            <div className={css.Line} />
            <div className={css.Number}>3</div>
          </div>
          <Fade clear>
            <div className={css.TextContainer}>
              <h2 className={css.Title}>Formation des équipes</h2>
              <p className={css.Body}>
                Formation de vos futures équipes. La durée des formations est
                variable selon le besoin des clients.
              </p>
            </div>
          </Fade>
        </div>
        <div className={css.HowToContainer}>
          <div className={css.LineContainer}>
            <div className={css.Line} />
            <div className={css.Number}>4</div>
          </div>
          <Fade clear>
            <div className={css.TextContainer}>
              <h2 className={css.Title}>Testing</h2>
              <p className={css.Body}>
              Vérification du niveau de chacun des opérateurs avant leurs premiers pas sur les desks.
              </p>
            </div>
          </Fade>
        </div>
        <div className={css.HowToContainer}>
          <div className={css.LineContainer}>
            <div className={css.LineLast} />
            <div className={css.Number}>5</div>
          </div>
          <Fade clear>
            <div className={css.TextContainer}>
              <h2 className={css.Title}>Mise en production</h2>
              <p className={css.Body}>
                Votre équipe est opérationnelle ! Souriez vous pouvez reprendre
                votre cœur de métier !
              </p>
            </div>
          </Fade>
        </div>
      </section>

      {/* /////////////////////    RDV    ///////////////////////// */}
      <Fade bottom>
        <section className={css.RdvSection}>
          <h1 className={css.RdvTitle}>Prendre rendez-vous</h1>
          <div className={css.FooterContacts}>
            <div className={css.ButtonContact}>
              <a
                className={css.link}
                href="mailto:info@bpo-css.com?subject=Demande de renseignements"
              >
                info@bpo-css.com
              </a>
            </div>
            {/*             <div className={css.ButtonContact}>
              <a className={css.FooterInfo} href="tel:0699469185">
                +33 (0) 6 99 46 91 85
              </a>
            </div> */}
          </div>
        </section>
      </Fade>
      {/* /////////////////////    Foorter    ///////////////////////// */}
      <Footer />
    </div>
  );
};

export default Process;
