import css from "./5-Career.module.css";

import Duo from "../Assets/Images/Illustrations/Duo.svg";

import JobCard from "../Components/UI/JobCard";

import Footer from "../Components/General/Footer";
import { Helmet } from "react-helmet";

const Career = (props) => {
  return (
    <div className={css.mainSection}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSS - Nous rejoindre</title>
        <link rel="canonical" href="http://bpo-css.com/career" />
        <meta
          name="description"
          content="Vous voulez rejoindre une équipe jeune et motivée, vous êtes à la recherche de nouvelles opportunités ?
          Alors envoyez nous vite votre CV et lettre de motivation !"
        />
      </Helmet>
      <section className={css.CandidatureSection}>
        <div className={css.CandidatureContainer}>
          <div className={css.CandidatureText}>
            <h1 className={css.CandidatureTitre}>Candidature spontanée</h1>
            <p className={css.CandidatureBody}>
              Vous voulez rejoindre une équipe jeune et motivée, vous êtes à la
              recherche de nouvelles opportunités ?{" "}
            </p>
            <p className={css.CandidatureBody}>
              Alors envoyez nous vite votre CV et lettre de motivation !
            </p>
            <div className={css.CandidatureButton}>
              <a
                className={css.link}
                href="mailto:recrutement@bpo-css.com?subject=Candidature spontanée"
              >
                recrutement@bpo-css.com
              </a>
            </div>
          </div>

          <div className={css.CandidatureVisuel}>
            <img
              src={Duo}
              alt="Candidature spontanée"
              className={css.CandidatureImage}
            />
          </div>
        </div>
      </section>
      <section className={css.JobSection}>
        <div className={css.JobContainer}>
          <h1 className={css.JobTitle}>Nos offres</h1>
          <div className={css.JobItems}>
            <JobCard
              title="Téléopérateurs Francophones"
              text1="- Baccalauréat (minimum)"
              text2="- Français (sans accent)"
              subject="mailto:rectrutement@bpo-css.com?subject=Candidature : Téléopérateurs Francophones"
            />
            <JobCard
              title="Téléopérateurs Anglophones"
              text1="- Baccalauréat (minimum)"
              text2="- Anglais courant (parlé et/ou écrit)"
              subject="mailto:rectrutement@bpo-css.com?subject=Candidature : Téléopérateurs Anglophones"
            />
            <JobCard
              title="Développeurs"
              text1="- React / Flutter"
              text2="- Ou Bubble"
              text3="- Ou Rust"
              subject="mailto:rectrutement@bpo-css.com?subject=Candidature : Développeurs"
            />
          </div>
        </div>
      </section>

      {/* /////////////////////    Foorter    ///////////////////////// */}
      <Footer />
    </div>
  );
};

export default Career;
