import css from "./Contacts.module.css";
import Footer from "../Components/General/Footer";
import ContactCard from "../Components/UI/ContactCard";

import Mail from "../Assets/Icons/Mail - Outline - Black.svg";
import Location from "../Assets/Icons/Location - Outline - Black.svg";
import Logo from "../Assets/Images/Logo/Logo PNG.png";
import { Helmet } from "react-helmet";

const Contacts = (props) => {
  return (
    <div className={css.mainSection}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSS - Nous contacter</title>
        <link rel="canonical" href="http://bpo-css.com/career" />
        <meta
          name="description"
          content="Page de contacts de CSS, contactez nous pour en savoir plus"
        />
      </Helmet>
      <div className={css.contactContainer}>
        <div className={css.contactBody}>
          <h1 className={css.contactTitle}>CONTACTS</h1>
          {/*         <a className={css.links} href="tel:0699469185">
        <ContactCard icon={Phone} text={"+33 (0) 6 99 46 91 85"} simple={true}/>
        </a> */}
          <a
            className={css.links}
            href="mailto:info@bpo-css.com?subject=Demande de renseignements"
          >
            <ContactCard icon={Mail} text={"info@bpo-css.com"} simple={true} />
          </a>
          <a
            className={css.links}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.fr/maps/place/CSS+(Customer+Service+Subcontractor)/@-18.8825314,47.5229323,15z/data=!4m5!3m4!1s0x0:0xefc47d95f6470551!8m2!3d-18.8825314!4d47.5229323"
          >
            <ContactCard
              icon={Location}
              text={"Immeuble Rayim"}
              text2={"Rue Ravoninahitriniarivo"}
              text3={"Antananarivo, Madagascar"}
            />
          </a>
        </div>
        <div className={css.contactImage}>
          <img src={Logo} alt="CSS Logo" className={css.Logo} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
