import Footer from "../Components/General/Footer";
import css from "./MetionsLegales.module.css";
import Logo from "../Assets/Images/Logo/Logo PNG.png"

const MentionsLegales = (props) => {
  return (
    <div className={css.mainSection}>
      <div className={css.MLContainer}>
        <div className={css.MLBody}>
          <h1 className={css.MainTitle}>Mentions Légales</h1>
          <h3 className={css.title}>Edition du site</h3>
          <p className={css.text}>
            Le site bpo-css.com est édité par la société Customer Service
            Subcontractor (CSS), Société à Responsabilité Limitée (SARL), au
            capital de 500 000 Malagasy Ariary, dont le siège social est situé
            immeuble RAYIM, rue Ravoninahitriniarivo Ankorondrano Analamanga,
            101 Antananarivo Renivohitra, Madagascar, immatriculée au Registre
            du Commerce et des Sociétés de Antananarivo sous le numéro 2022 B
            00003.
          </p>
          <h3 className={css.title}>Responsable de publication</h3>
          <p className={css.text}>
            Stanislas de Poyferré - Président Directeur Général de CSS
          </p>
          <h3 className={css.title}>Nous contacter</h3>
          <p className={css.text}>
            Via e-mail : info@bpo-css.com
            <br />
            Via WhatsApp : +33 (0) 6 99 46 91 85
          </p>
          <h3 className={css.title}>Hébergeur</h3>
          <p className={css.text}>
            Le site bpo-css.com est hébergé par la société OVH SAS dont le siège
            social est située 2 rue Kellermann - 59100 Roubaix - France
          </p>
          <h3 className={css.title}>Utilisation du site</h3>
          <p className={css.text}>
            Nous mettons tout en œuvre pour nous assurer que les informations
            publiées sur ce site Web soient à jour et pour fournir aux
            utilisateurs des informations exactes. Les utilisateurs doivent être
            conscients que les informations disponibles sur Internet, ne peut
            pas être considéré comme totalement fiable. Nous ne pouvons donc pas
            garantir l'exactitude des données figurant sur le site.<br/><br/>Merci de
            nous signaler toute erreur ou omission à l'adresse e-mail suivante :
            info@bpo-css.com. <br/><br/>Par ailleurs, Customer Service Subcontractor (CSS)
            peut être amenée à modifier et/ou améliorer le contenu de ce site, y
            compris le contenu des mentions légales, à tout moment et sans
            préavis et n'assume aucune responsabilité résultant de l'exactitude
            des informations diffusées sur ce site.
          </p>
        </div>
        <div className={css.contactImage}>
          <img src={Logo} alt="CSS Logo" className={css.Logo} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MentionsLegales;
