import { Link } from "react-router-dom";
import css from "./Footer.module.css";
import MailWhite from "../../Assets/Icons/Solutions/Mail - White.svg";




const Footer = () => {
    return(
          <section className={css.FooterSection}>
          <div className={css.FooterContacts}>
{/*             <a className={css.FooterInfo} href="tel:0699469185">        
            <img className={css.FooterIcon} src={PhoneWhite} alt="Téléphone"/>
            </a>  */}
            <a className={css.FooterInfo} href="mailto:info@bpo-css.com?subject=Demande de renseignements">        
            <img className={css.FooterIcon} src={MailWhite} alt="E-Mail"/>
            </a> 
          </div>
            <div className={css.FooterLinks}>
            <Link to="/mentionslegales" className={css.FooterButton}>
              Mentions Légales
            </Link>
            <Link to="/contact" className={css.FooterButton}>
              Contact
            </Link>
            </div>
          </section>
    )
}

export default Footer;