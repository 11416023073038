import css from "./2-Solutions.module.css";

import SplitContainer from "../Components/UI/SplitContainer";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";

import GirlRed from "../Assets/Icons/Characters/Girl - Red BG.svg";
import GirlBlack from "../Assets/Icons/Characters/Girl - Black BG.svg";
import GuyBlack from "../Assets/Icons/Characters/Guy - Black BG.svg";
import GuyBlond from "../Assets/Icons/Characters/Guy - Blond BG Grey.svg";
import GuyBlue from "../Assets/Icons/Characters/Guy - Blue BG.svg";

import MailWhite from "../Assets/Icons/Solutions/Mail - White.svg";
import PickYourTeam from "../Assets/Images/Illustrations/Pick your team.svg";
import Rock from "../Assets/Icons/Rock.svg";
import Books from "../Assets/Icons/Books.svg";
import Thunder from "../Assets/Icons/Thunder.svg";

import Phone from "../Assets/Icons/Solutions/Phone - Black.svg";
import Chat from "../Assets/Icons/Solutions/Chat - Black.svg";
import Data from "../Assets/Icons/Solutions/Data1 - Black.svg";
import Codeur from "../Assets/Icons/Solutions/Coding - Black.svg";
import Custom from "../Assets/Icons/Solutions/Cutom - White.svg";
import { useRef } from "react";
import { Helmet } from "react-helmet";

const Solutions = (props) => {
  const scollToRef = useRef();

  return (
    <div className={css.mainSection}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSS - Nos Solutions</title>
        <link rel="canonical" href="http://bpo-css.com/solutions" />
        <meta
          name="description"
          content="Chez CSS, nous prônons la simplicité !
          Pas de termes compliqués ou de listes à rallonge, vous avez quatre options. Les callers pour vos appels, les chatters pour votre messagerie, les datavores pour vos données et les codeurs pour tous vos développements.
          Et si vous n'avez pas trouvé contactez nous !"
        />
      </Helmet>

      {/* /////////////////     INTRO    ///////////////// */}
      <section className={css.IntroSection}>
        <div className={css.IntroContainer}>
          <div className={css.IntroText}>
            <h1 className={css.Title}>Nos solutions</h1>
            <p className={css.Text}>
              Chez CSS, nous prônons la simplicité ! <br />
              <br />
              Pas de termes compliqués ou de listes à rallonge, vous avez quatre
              options. Les callers pour vos appels, les chatters pour votre
              messagerie, les datavores pour vos données et les codeurs pour
              tous vos développements. <br />
              <br />
              Et si vous n'avez pas trouvé contactez nous !
            </p>
            <h3 className={css.Title}>
              Pour chaque problème,
              <br />
              Nous avons une solution !
            </h3>
          </div>
          <div className={css.IntroImageContainer}>
            <button
              className={css.IntroButton}
              onClick={() =>
                scollToRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                })
              }
            >
              <img
                src={PickYourTeam}
                alt="Pick your team - Illustration"
                className={css.IntroImage}
              />
            </button>
          </div>
        </div>
      </section>
      {/* /////////////////     BANDEAU    ///////////////// */}
      <section className={css.BandeauSection}>
        <div className={css.BandeauContainer}>
          <div className={css.BandeauItem}>
            <img src={Rock} alt="Youth icon" className={css.BandeauIcon} />
            <h4>Jeunes</h4>
          </div>
          <div className={css.BandeauItem}>
            <img src={Books} alt="Book icon" className={css.BandeauIcon} />
            <h4>Formés</h4>
          </div>
          <div className={css.BandeauItem}>
            <img src={Thunder} alt="Thunder icon" className={css.BandeauIcon} />
            <h4>Dynamiques</h4>
          </div>
        </div>
      </section>
      {/* /////////////////     SOLUTIONS  ///////////////// */}
      <section className={css.SolutionSection} ref={scollToRef}>
        {/*         <Fade clear> */}
        <SplitContainer
          title={"CALLERS"}
          icon={Phone}
          text={
            "Les callers vous accompagnent dans tous vos besoins téléphoniques (flux chauds) : hotline, service client, SAV, prises de rendez-vous, …"
          }
          price={"En savoir plus"}
          image={GirlRed}
        />
        {/* </Fade> */}
        {/* <Fade clear> */}
        <SplitContainer
          title={"CHATTERS"}
          icon={Chat}
          text={
            "Les chatters sont là pour couvrir tous vos besoins de messagerie (flux froids) : chats, emailing, réseaux sociaux, forums, …"
          }
          price={"En savoir plus"}
          image={GirlBlack}
        />
        {/*         </Fade>
<Fade clear> */}
        <SplitContainer
          title={"DATAVORES"}
          icon={Data}
          text={
            "De la simple saisie jusqu’à la transformation complète de vos données. Nos datavores vous accompagnent dans tous vos besoins."
          }
          price={"En savoir plus"}
          image={GuyBlue}
        />
        {/* </Fade>
<Fade clear> */}
        <SplitContainer
          title={"CODEUR"}
          icon={Codeur}
          text={
            "Pour tous vos développements web, application, logiciels, automatisations. Nos codeurs sont là pour vous !"
          }
          price={"En savoir plus"}
          image={GuyBlack}
        />
        {/*       </Fade> */}
      </section>
      <Fade bottom>
        <section className={css.TopFooterSection}>
          <h1 className={css.FooterTitle}>
            Vous n'avez pas trouvé votre bonheur ?
          </h1>
          <SplitContainer
            title={"CUSTOM"}
            icon={Custom}
            text={"Construisez votre équipe sur mesure. "}
            price={"En savoir plus"}
            image={GuyBlond}
            white={true}
          />
          {/*       <span className={css.separator} /> */}
          <h2 className={css.FooterTitle}>
            Contactez-nous, s'il y a un problème nous avons la solution !
          </h2>
          <div className={css.FooterContacts}>
            {/*           <a className={css.FooterItems} href="tel:0699469185">
            <img className={css.FooterIcon} src={PhoneWhite} alt="Téléphone" />
            <h3 className={css.FooterInfo}>+33 (0) 6 99 46 91 85</h3>
            </a> */}
            <a
              className={css.FooterItems}
              href="mailto:info@bpo-css.com?subject=Demande de renseignements"
            >
              <img className={css.FooterIcon} src={MailWhite} alt="E-Mail" />
              <h3 className={css.FooterInfo}>info@bpo-css.com</h3>
            </a>
          </div>
        </section>
      </Fade>
      <section className={css.FooterSection}>
        <div className={css.FooterLinks}>
          <Link to="/mentionslegales" className={css.FooterButton}>
            Mentions Légales
          </Link>
          <Link to="/contact" className={css.FooterButton}>
            Contact
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Solutions;
