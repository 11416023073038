import css from "./ContactCard.module.css";

const ContactCard = (props) => {
  return (
    <div className={css.MainSection}>
      <img src={props.icon} alt="Icones" className={css.icon} />
      {props.simple ? <h3 className={css.text}>{props.text}</h3> : <h3 className={css.text}>{props.text}<br/>{props.text2}<br/>{props.text3}</h3>}
      
    </div>
  );
};

export default ContactCard;
