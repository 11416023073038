import css from "./4-About.module.css";

import { Fade } from "react-reveal";

import RocketImage from "../Assets/Images/Illustrations/Rocket.svg";
import MadagascarImage from "../Assets/Images/Illustrations/Madagascar.png";

import Salaire from "../Assets/Icons/Salaire.svg";
import Formation from "../Assets/Icons/Formation.svg";
import Ambiance from "../Assets/Icons/Ambiance.svg";
import Opportunite from "../Assets/Icons/Opportunite.svg";
import Equipement from "../Assets/Icons/Equipement.svg";
import Brain from "../Assets/Icons/Brain.svg";
import Internet from "../Assets/Icons/Internet.svg";
import Tag from "../Assets/Icons/Tag.svg";
import Time from "../Assets/Icons/Time.svg";
import Plant from "../Assets/Icons/Plant.svg";

import clients from "../Assets/Icons/Clients - Red.svg";
import partenariat from "../Assets/Icons/Partenariat - Red.svg";
import connaissances from "../Assets/Icons/Connaissances - Red.svg";
import quality from "../Assets/Icons/Quality - Red.svg";

import Card from "../Components/UI/Card";
import Footer from "../Components/General/Footer";
import { Helmet } from "react-helmet";

const About = (props) => {
  return (
    <div className={css.mainSection}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSS - Qui sommes nous</title>
        <link rel="canonical" href="http://bpo-css.com/about" />
        <meta
          name="description"
          content="Bienvenue chez CSS la nouvelle « Talent Factory » de Madagascar !
          Fraîchement créée en janvier 2022, CSS a pour ambition de casser les codes du secteur tant au niveau des services apportés aux clients qu’au niveau de l’attention que nous portons a nos équipes !"
        />
      </Helmet>
      {/* /////////////////     INTRO    ///////////////// */}
      <Fade clear>
        <section className={css.IntroSection}>
          <div className={css.IntroContainer}>
            <div className={css.IntroText}>
              <h1 className={css.Title}>The talent factory</h1>
              <p className={css.Text}>
                Bienvenue chez CSS la nouvelle « Talent Factory » de Madagascar
                !<br />
                <br />
                Fraîchement créée en janvier 2022, CSS a pour ambition de casser
                les codes du secteur tant au niveau des services apportés aux
                clients qu’au niveau de l’attention que nous portons a nos
                équipes !
              </p>
              <h3 className={css.SubTitle}>
                Alors prêt à rejoindre l'aventure ?
              </h3>
            </div>
            <div className={css.IntroImageContainer}>
              <img src={RocketImage} alt="Rocket" className={css.IntroImage} />
            </div>
          </div>
        </section>
        {/* /////////////////     MADAGASCAR   ///////////////// */}
        <section className={css.MadagascarSection}>
          <div className={css.MadaContainer}>
            <div className={css.MadaImageContainer}>
              <img
                src={MadagascarImage}
                alt="Madagascar"
                className={css.MadaImage}
              />
            </div>
            <div className={css.MadaText}>
              <h1 className={css.Title}>
                Madagascar<p>Le pays, pas le film ;)</p>
              </h1>

              <h3>Pourquoi choisir Madagascar ?</h3>

              <p className={css.Text}>
                <img src={Brain} alt="Cerveau" className={css.MadaIcon} />
                Une population jeune et talentueuse.
              </p>
              <p className={css.Text}>
                <img src={Time} alt="Horloge" className={css.MadaIcon} />
                Un décalage horaire d'une heure par rapport à l'Europe.
              </p>
              <p className={css.Text}>
                <img src={Internet} alt="Internet" className={css.MadaIcon} />
                Une connexion internet haut débit grâce à la fibre optique.
              </p>
              <p className={css.Text}>
                <img
                  src={Tag}
                  alt="Etiquette de prix"
                  className={css.MadaIcon}
                />
                Un coût très attractif.
              </p>
            </div>
          </div>
        </section>
        {/* /////////////////     NOS VALEURS    ///////////////// */}
        <section className={css.ValeursSection}>
          <div className={css.ValeursContainer}>
            <h1 className={css.ValeursTitle}>Nos Valeurs</h1>
            <div className={css.ValeursItems}>
              <Card
                image={clients}
                title={"HUMAIN"}
                text={
                  "Nous avons à cœur d’offrir un cadre de travail unique à nos collaborateurs avec les opportunités qu’ils méritent après tout sans eux nous ne serions rien."
                }
              />
              <Card
                image={partenariat}
                title={"PARTENARIAT"}
                text={
                  "Nous ne cherchons pas à remplir de simples contrats mais à établir des relations de confiance sur la durée pour que vous puissiez toujours compter sur nous."
                }
              />
              <Card
                image={connaissances}
                title={"CONNAISSANCES"}
                text={
                  "Nous prenons le temps d’apporter à nos équipes les meilleures formations. Ils sont mieux préparés et par conséquents plus à l’aise et efficaces dans leurs tâches."
                }
              />
              <Card
                image={quality}
                title={"QUALITÉ"}
                text={
                  "Tous les choix que nous prenons, que ce soit au niveau de l’humain ou des process ont pour objectif principal d’améliorer la qualité de nos rendus."
                }
              />
            </div>
          </div>
        </section>

        {/* /////////////////     QUALITE    ///////////////// */}
        {/*       <section className={css.QualitySection}>
        <div className={css.QualityContainer}>
          <div className={css.QualityTitleContainer}>
            <h1 className={css.Title}>La qualité</h1>
            <p className={css.Text}>
              Chez CSS nous avons associé un qualiticien à un superviseur sur
              chaque desk. Ce système permet de réaliser une double vérification
              et ainsi accroitre nos capacités de détection en plus de réduire
              au minimum les erreurs.
            </p>
          </div>
          <div className={css.QualityText}>
            <div className={css.body}>
              <div className={css.QualityItem}>
                <div className={css.QualityTitle}>
                  <h3>Superviseur</h3>
                  <img
                    src={RondOrange}
                    alt="Cercle Orange"
                    className={css.QualityCercle}
                  />
                </div>
                <p>
                  Vérification de 15% aléatoire du travail de chaque
                  collaborateur tous les jours
                </p>
              </div>
              <div className={css.QualityItem}>
                <div className={css.QualityTitle}>
                  <h3>Qualiticien</h3>
                  <img
                    src={RondVert}
                    alt="Cercle Vert"
                    className={css.QualityCercle}
                  />
                </div>
                <p>
                  Vérification de 100% du travail d’un collaborateur par jour de
                  manière aléatoire.
                </p>
              </div>
              <div className={css.QualityItem}>
                <div className={css.QualityTitle}>
                  <h3>Collaborateurs</h3>
                  <img
                    src={RondBlue}
                    alt="Cercle Bleu"
                    className={css.QualityCercle}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css.QualityImageContainer}>
            <img src={QualityTable} alt="Rocket" className={css.QualityImage} />
          </div>
        </div>
      </section> */}
        {/* /////////////////     SECURITE    ///////////////// */}
        {/*   <section className={css.SecuriteSection}>
        <div className={css.SecuriteContainer}>
          <div className={css.SecuriteImageContainer}>
            <img src={SecurityImage} alt="Security" className={css.SecuriteImage} />
          </div>
          <div className={css.SecuriteText}>
            <h1 className={css.Title}>La sécurité</h1>
            <p className={css.Text}>
              Lorem ipsum dolor sit amet. Cum fuga voluptatibus eum maiores
              libero ex corrupti ipsum et accusamus adipisci sed natus autem.
              Non pariatur debitis vel asperiores facilis ut libero fuga id
              velit officia. Et animi dignissimos sed quae quos est consequuntur
              alias et numquam culpa id internos illum sit nemo omnis At
              deserunt voluptate.
            </p>
          </div>
        </div>
      </section> */}
        {/* /////////////////     RSE    ///////////////// */}
        <section className={css.RseSection}>
          {/*         <div className={css.RseContainer}> */}
          {/* <div className={css.RseTextContainer}> */}

          <h1 className={css.Title}>Notre RSE</h1>
          <p className={css.RseSubTitle}>
            A notre échelle, nous essayons d’apporter de nouvelles opportunités
            à la jeunesse Malgache, en suivant six axes.
          </p>
          <div className={css.RseBody}>
            <div className={css.RseItem}>
              <div className={css.RseIconConainer}>
                <img src={Salaire} alt="Salaires" className={css.RseIcon} />
              </div>
              <div className={css.RseText}>
                <h3 className={css.RseTitle}>Salaires</h3>
                <p>Nous payons en moyenne 30% de plus que nos concurrents.</p>
              </div>
            </div>
            <div className={css.RseItem}>
              <div className={css.RseIconConainer}>
                <img src={Formation} alt="Formation" className={css.RseIcon} />
              </div>
              <div className={css.RseText}>
                <h3 className={css.RseTitle}>Formation</h3>
                <p>
                  Indispensable pour l’avenir de nos collaborateurs, nous nous
                  efforçons à leur offrir les meilleurs accompagnements.
                </p>
              </div>
            </div>
            <div className={css.RseItem}>
              <div className={css.RseIconConainer}>
                <img src={Ambiance} alt="Ambiance" className={css.RseIcon} />
              </div>
              <div className={css.RseText}>
                <h3 className={css.RseTitle}>Cadre de travail</h3>
                <p>
                  Chez CSS nous avons à cœur d’offrir une culture d’entreprise
                  permettant à chacun de trouver sa place.
                </p>
              </div>
            </div>
            <div className={css.RseItem}>
              <div className={css.RseIconConainer}>
                <img
                  src={Opportunite}
                  alt="Opportunité"
                  className={css.RseIcon}
                />
              </div>
              <div className={css.RseText}>
                <h3 className={css.RseTitle}>Opportunité</h3>
                <p>
                  La méritocratie est essentielle à nos yeux. Nous souhaitons
                  offrir à nos meilleurs profils des postes à plus forte
                  responsabilité.
                </p>
              </div>
            </div>
            <div className={css.RseItem}>
              <div className={css.RseIconConainer}>
                <img
                  src={Equipement}
                  alt="Equipement"
                  className={css.RseIcon}
                />
              </div>
              <div className={css.RseText}>
                <h3 className={css.RseTitle}>Equipements</h3>
                <p>
                  Nous offrons des outils à la pointe de la technologie ainsi
                  qu’un meilleur cadre de travail.
                </p>
              </div>
            </div>
            <div className={css.RseItem}>
              <div className={css.RseIconConainer}>
                <img src={Plant} alt="Ecologie" className={css.RseIcon} />
              </div>
              <div className={css.RseText}>
                <h3 className={css.RseTitle}>Ecologie</h3>
                <p>
                  Mise en place d'une politique écologique dans nos usages du
                  quotidien ainsi que dans nos investissements.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Fade>
      {/* ////////////////    FOOTER    ///////////////////////// */}
      <Footer />
    </div>
  );
};

export default About;
