import css from "./IconBullet.module.css";

const IconBullet = (props) => {
  return (
    <div className={css.SolutionBullet}>
      <img src={props.image} alt="icon" className={css.SolutionIcon} />
      <h4 className={css.SolutionBulletTitle}>{props.title}</h4>
   {/*    <span className={css.separator} /> */}
      <p className={css.SolutionBulletText}>{props.body}</p>
    </div>
  );
};

export default IconBullet;
