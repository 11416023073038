import css from "./JobCard.module.css";

const JobCard = (props) => {
  return (
    <div className={css.mainSection}>
      <h3 className={css.title}>{props.title}</h3>
      <div className={css.Items}>
        <p className={css.text}>{props.text1}</p>
        <p className={css.text}>{props.text2}</p>
        <p className={css.text}>{props.text3}</p>
      </div>
        <a href={props.subject}>Postuler</a>
    </div>
  );
};

export default JobCard;
