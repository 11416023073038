import css from "./NoMatch.module.css";
import WrongPage from "../Assets/Images/Illustrations/NoMatch.svg";
import Footer from "../Components/General/Footer";

const NoMatch = () => {
  return (
    <div className={css.mainContainer}>
      <img src={WrongPage} alt="This page doesn't exist" className={css.image} />
      <div className={css.footerContainer}>
      <Footer/>
      </div>
    </div>
  );
};

export default NoMatch;
