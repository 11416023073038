import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Components/General/Layout";
import Password from "./Pages/Password";
import ScrollToTop from "./Components/General/ScrollToTop";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import RouteMap from "./Routes";

function App() {
  const [isUnlocked, setIsUnlocked] = useState("");
  const TRACKING_ID = "G-S022NWRTQ2"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  /* const passwordHandler = () => {
  setIsUnlocked(false)
} */

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CSS - Customer Service Subcontractor</title>
        <link rel="canonical" href="http://bpo-css.com/" />
        <meta
          name="description"
          content="CSS est une « Talent Factory » qui a pour vocation d’aider les entreprises dans leur quotidien. Nous souhaitons casser les codes vieillissants du BPO (Business Process Outsourcing) en apportant plus de simplicité et de modernité. "
        />
        <meta
          name="keywords"
          content="CSS Talent Factory, Centre BPO, Call Center, Externalisation à Madagasacar"
        />
      </Helmet>
      {isUnlocked !== "PitStop" ? (
        <Layout>
          <ScrollToTop />
          <RouteMap />
        </Layout>
      ) : (
        <Routes>
          <Route
            path="*"
            element={
              <Password validate={(password) => setIsUnlocked(password)} />
            }
          />
        </Routes>
      )}
    </div>
  );
}

export default App;
