import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/1-Home";
import Solutions from "./Pages/2-Solutions";
import Process from "./Pages/3-Process";
import About from "./Pages/4-About";
import Career from "./Pages/5-Career";
import NoMatch from "./Pages/NoMatch";
import MentionsLegales from "./Pages/MentionsLegales";
import Contacts from "./Pages/Contacts";


const RouteMap = () => {
  return (
    <Routes>
    <Route index element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="solutions" element={<Solutions />} />
    <Route path="process" element={<Process />} />
    <Route path="about" element={<About />} />
    <Route path="career" element={<Career />} />
    <Route path="mentionslegales" element={<MentionsLegales />} />
    <Route path="contact" element={<Contacts />} />
    <Route path="*" element={<NoMatch />} />
  </Routes>

  )
}
export default RouteMap;