import css from "./Card.module.css";

const Card = (props) => {
  return (
    <div className={css.mainSection}>
        <div className={css.imageCT}>
      <img className={css.image} src={props.image} alt="Icon" />

        </div>
      <h3 className={css.title}>{props.title}</h3>
      <p className={css.text}>{props.text}</p>
    </div>
  );
};

export default Card;
