import { useEffect, useState } from "react";
import css from "./SplitContainer.module.css";

const SplitContainer = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className={css.mainSection}>
      <div className={css.imageSection}>
        <img className={css.image} src={props.image} alt="{props.title}" />
      </div>
      <div className={css.textSection}>
        {/* <h2 className={css.title}>{props.title}</h2> */}

        {!isMobile && <img className={css.icon} src={props.icon} alt="Icon" />}
        {/* <span className={`${!props.white ?  css.separator  : css.separatorWhite}`} /> */}
        <div className={css.textContainer}>
          <p className={`${!props.white ? css.text : css.textWhite}`}>
            {props.text}
          </p>
          <a className={css.ButtonItems} href="mailto:info@bpo-css.com?subject=Demande de renseignements">
          <div className={`${!props.white ? css.priceTag : css.priceTagWhite}`}>
            {props.price}
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SplitContainer;
