import Card from "../Components/UI/Card";
import css from "./1-Home.module.css";
import { Link } from "react-router-dom";
import IconBullet from "../Components/UI/IconBullet";
import TextTransition, { presets } from "react-text-transition";

import Logo from "../Assets/Images/Logo/Logo PNG.png";
import clients from "../Assets/Icons/Clients - Red.svg";
import quality from "../Assets/Icons/Quality - Red.svg";
import RSE from "../Assets/Icons/RSE - Red.svg";
import surMesure from "../Assets/Icons/SurMesure - Red.svg";
import tarif from "../Assets/Icons/Tarif - Red.svg";
import turnover from "../Assets/Icons/TurnOver - Red.svg";
import GirlRed from "../Assets/Icons/Characters/Girl - Red.svg";
import GuyBlack from "../Assets/Icons/Characters/Guy - Black.svg";
import Phone from "../Assets/Icons/Solutions/Phone - Red.svg";
import Chat from "../Assets/Icons/Solutions/Chat - Red.svg";
import Data from "../Assets/Icons/Solutions/Data1 - Red.svg";
import CodeurIcon from "../Assets/Icons/Solutions/Coding - Red.svg";
import Custom from "../Assets/Icons/Solutions/Rocket - Red.svg";
import Arrow from "../Assets/Icons/Arrow.svg";
import { Fade } from "react-reveal";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "../Components/General/Footer";
import { Helmet } from "react-helmet";


const TEXTS = [
  "Votre service client",
  "traiter vos datas",
  "Créer vos sites",
  "Vos annotations de données",
  "Gérer vos messageries",
  "Développer vos logiciels",
];

const Home = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const scrollHandler = () => {
    if (window.scrollY >= 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <div className={css.mainSection}>
    <Helmet>
          <meta charSet="utf-8" />
          <title>CSS - Accueil</title>
          <link rel="canonical" href="http://bpo-css.com/home" />
          <meta name="description" content="Welcome to CSS homepage"/>
        </Helmet>
      {/* /////////////////////     INTRO     ///////////////////////// */}
      <section className={css.introSection}>
        <h1 className={css.introTitle}>
          Besoin d'un coup de main pour{" "}
          <TextTransition springConfig={presets.default}>
            <h1 className={css.introTitleMoving}>
              {TEXTS[index % TEXTS.length]} ?
            </h1>
          </TextTransition>
        </h1>
        {!scrolled && (
          <div className={css.IntroArrowContainer}>
            <p>Continuer</p>

            <img src={Arrow} className={css.IntroArrow} alt="" />
          </div>
        )}
      </section>

      {/* /////////////////////   WHO   ///////////////////////// */}
      <section className={css.WhoSection}>
        <div className={css.SolutionItems}>
          <Fade left>
            <div className={css.SolutionTextContainer}>
              <h1 className={css.SolutionTitle}>Qui sommes nous</h1>
              <p className={css.SolutionText}>
                CSS est une « Talent Factory » qui a pour vocation d’aider les
                entreprises dans leur quotidien. 
                Nous souhaitons casser les codes vieillissants du BPO en
                apportant plus de simplicité et de modernité.
              </p>
              <h3>Pourquoi choisir un centre BPO ?</h3>
              <br/> 
              <div className={css.BulletContainer}>
                <div className={css.BulletNumber}>1</div>
                <p className={css.BulletText}>
                  Se concentrer sur son cœur de métier
                </p>
              </div>
              <div className={css.BulletContainer}>
                <div className={css.BulletNumber}>2</div>
                <p className={css.BulletText}>
                  Obtenir une expertise métier
                </p>
              </div>
              <div className={css.BulletContainer}>
                <div className={css.BulletNumber}>3</div>
                <p className={css.BulletText}>
                  Offrir des services de meilleure qualité
                </p>
              </div>
              <div className={css.BulletContainer}>
                <div className={css.BulletNumber}>4</div>
                <p className={css.BulletText}>
                  Réduire et contrôler ses coûts 
                </p>
              </div>
              <div className={css.BulletContainer}>
                <div className={css.BulletNumber}>5</div>
                <p className={css.BulletText}>
                  Gagner en liberté et flexibilité
                </p>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className={css.SolutionImageContainer}>
              <img src={Logo} alt="Logo de CSS" className={css.WhoImage} />
            </div>
          </Fade>
        </div>
      </section>

      {/* /////////////////////    SOLUTIONS    ///////////////////////// */}
      <section className={css.SolutionSection}>
        <div className={css.SolutionItems}>
          <Fade left>
            <div className={css.SolutionTextContainer}>
              <h1 className={css.SolutionTitle}>Nos Solutions</h1>
              <p className={css.SolutionText}>
                Nous mettons a disposition nos experts pour répondre à tous vos
                besoins humain. Faites votre choix parmis
              </p>
              <IconBullet
                image={Phone}
                title={"Caller"}
                body={"Appels entrants & sortants"}
              />
              <IconBullet
                image={Chat}
                title={"Chatter"}
                body={"Chats, blogs, forums, ..."}
              />
              <IconBullet
                image={Data}
                title={"Datavore"}
                body={"Saisie et traitement de données"}
              />
              <IconBullet
                image={CodeurIcon}
                title={"Codeur"}
                body={"Web, App, ERP, automatisation, ..."}
              />
              <IconBullet
                image={Custom}
                title={"Custom"}
                body={"Un besoin spécifique ? Contactez nous !"}
              />

            </div>
          </Fade>
          <Fade right>
            <div className={css.SolutionImageContainer}>
              <img
                className={css.SolutionImage}
                src={GuyBlack}
                alt="Solutions"
              />
              <img
                className={css.SolutionImage}
                src={GirlRed}
                alt="Solutions"
              />
            </div>
          </Fade>
        </div>
        <Fade up>
            <Link to="/solutions" className={css.button}>
                En Savoir plus
              </Link>
        </Fade>
      </section>

      {/* <h1 className={css.whoTitle}>Chez CSS Nous</h1>
        <Fade left>
          <div className={css.whoTag}>
            <h3 className={css.whoTagTxt}>
              Révolutionnons le monde de l’externalisation de service.
            </h3>
          </div>
        </Fade>
        <Fade right>
          <div className={css.whoTag}>
            <h3 className={css.whoTagTxt}>
              Plaçons la qualité au centre de nos réflexions.
            </h3>
          </div>
        </Fade>
        <Fade left>
          <div className={css.whoTag}>
            <h3 className={css.whoTagTxt}>
              Vous permettrons de vous concentrer sur votre cœur de business.
            </h3>
          </div>
        </Fade>
        <Fade right>
          <div className={css.whoTag}>
            <h3 className={css.whoTagTxt}>
              Grace à notre équipe jeune et motivée, avide de nouveaux défis !
            </h3>
          </div>
        </Fade>
        <Fade bottom>
          <h2 className={css.whoTitle}>Alors besoin d'un coup de main ?</h2>
        </Fade> */}

      {/* /////////////////////    AVANTAGE    ///////////////////////// */}
      <section className={css.AvantageSection}>
        <Fade clear>
          <h1 className={css.AvantageTitle}>Avantages</h1>
          <div className={css.AvantageItems}>
            <Card
              image={quality}
              title={"QUALITÉ"}
              text={
                "La qualité est notre priorité! Découvrez nos process dans la section : Qui Sommes Nous."
              }
            />
            <Card
              image={clients}
              title={"SATISFACTION CLIENT"}
              text={
                "Un client satisfait est un client qui reste. Nous traitons vos clients comme les nôtres."
              }
            />
            <Card
              image={surMesure}
              title={"SUR-MESURE"}
              text={
                "Des prestations adaptées à vos besoins. Du besoin ponctuel aux solutions à long terme, nous trouverons une solution."
              }
            />
            <Card
              image={turnover}
              title={"RETENTION DES TALENTS"}
              text={
                "Un turn over réduit grâce à notre culture d’entreprise et aux opportunités disponibles."
              }
            />
            <Card
              image={RSE}
              title={"RSE"}
              text={
                "Apporter une dimension humaine dans chaque projet est essentiel. Découvrez nos actions dans la section : Qui Sommes Nous."
              }
            />
            <Card
              image={tarif}
              title={"TARIFS"}
              text={
                "Malgré tous nos atouts nous avons à cœur de vous apporter un tarif toujours plus compétitifs."
              }
            />
          </div>
        </Fade>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
