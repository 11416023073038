import React, { useState } from "react";
import css from "./Password.module.css";
import logo01 from "../Assets/Site En MaintenanceV2.svg";
import logo from "../Assets/2020 Logo CSS blanc.png";

const Password = (props) => {
  const [enteredPassword, setEnteredPassword] = useState("");
  const passwordHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  return (
    <div className={css.mainContainer}>
      <img src={logo} alt="CSS" className={css.logo} />
      <form className={css.form}>
        <input
          type="password"
          value={enteredPassword}
          onChange={passwordHandler}
          className={css.inputBox}
        />
        <button type="submit" onClick={() => props.validate(enteredPassword)}>
          Enter
        </button>
      </form>
      <div className={css.contactContainer}>
        <h1 className={css.contactTitre}>Besoin d'un coup de main ?</h1>
        <div className={css.contactInfo}>
          <div className={css.contactText}>Contactez nous</div>
          <a href="mailto:info@bpo-css.com">info@bpo-css.com</a>
          />
        </div>
      </div>

      <img src={logo01} alt="Customer Service Subcontractor" className={css.construction} />
    </div>
  );
};

export default Password;
